import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Category = ({ categoryName, image, pageName }) => {
  if (categoryName === "Produkcja zywnosci") categoryName = "Produkcja żywności"
  return (
    <div className="category">
      <Link to={pageName.toLowerCase()}>
        <GatsbyImage image={image} alt={categoryName} className="gallery-img" />
        <h2>{categoryName}</h2>
      </Link>
    </div>
  )
}

export default Category
