import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import Category from "../components/Category"

import "../assets/css/styles.css"
import Seo from "../components/Seo"
import TopMenu from "../components/TopMenu"

export const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "categoryImages" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            height: 500
          )
        }
      }
    }
  }
`

export default function Home() {
  const categoryOrder = [
    "Produkcja_zywnosci",
    "Sklep_techniczny",
    "Ekologiczna_energia",
  ]
  const data = useStaticQuery(query)
  const nodes = data.allFile.nodes
  return (
    <div className="mainPage">
      <Seo title="Strona główna" />
      <TopMenu />
      <div className="row justify-content-center">
        <div className="col-sm-4">
          <StaticImage
            src="../assets/images/logo.png"
            alt="EKa"
            placeholder="tracedSVG"
            layout="fullWidth"
            className="welcomePageLogo"
          />
        </div>
      </div>
      <div className="categories">
        {categoryOrder.map((category, index) => {
          const image = nodes.find(element => element.name === category)
          const { name } = image
          const pathToImage = getImage(image)
          const nameToDisplay = name.replace(/_/g, " ")
          return (
            <Category
              key={index}
              image={pathToImage}
              categoryName={nameToDisplay}
              pageName={name}
            />
          )
        })}
      </div>
    </div>
  )
}
